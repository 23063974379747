/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.StaffEmailCampaignsPage .emailCampaigns {
  margin: 10rem 3rem;
  padding: 2rem;
  border-radius: 0.7rem;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 600;
}
.StaffEmailCampaignsPage .emailCampaigns .emailCampaign {
  cursor: pointer;
  margin-bottom: 2rem;
  border-radius: 0.7rem;
}
.StaffEmailCampaignsPage .emailCampaigns .emailCampaign:nth-child(even) {
  background: #f9f6ef;
}
.StaffEmailCampaignsPage .emailCampaigns .emailCampaign:nth-child(odd) {
  background: #e6e0d0;
}
.StaffEmailCampaignsPage .emailCampaigns .emailCampaign table {
  margin-left: auto;
  margin-right: auto;
  border-width: 0.1rem;
  border-color: black;
}
